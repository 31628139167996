import React, { useEffect, useState } from "react";

import { Dialog, Grid, makeStyles, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { setIsOpenLoginModal } from "../../../../redux/actions/auth";
import { logError, logInfo } from "../../../../services/logging";
import firebase from "../../../../utils/firebase";
import {
  getLocalStore,
  removeLocalStore,
} from "../../../../utils/storage/localStorage";
import ButtonContained from "../../../common/buttons/ButtonContained";
import FacebookSignIn from "./FacebookSignIn";
import FirebaseAnonymousSignIn from "./FirebaseAnonymousSignIn";
import GoogleSignIn from "./GoogleSignIn";
import { useForm } from "react-hook-form";
import SignInPage from "./SignInPage";
import RegistrationPage from "./RegistrationPage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperFullWidth": {
      borderRadius: theme.shape.borderRadius,
    },
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
      },
    },
  },
  form: {
    padding: theme.spacing(1, 1, 0, 1),
    display: "flex",
    flexDirection: "column",
    // color: theme.palette.text.primaryTextColor,
  },
  titleSection: {
    padding: theme.spacing(2),
    textAlign: "center",
    // color: theme.palette.text.primaryTextColor,
  },
  makeMiddle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const LoginDialog = () => {
  const [authError, setAuthError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isLoginOrRegister, setIsLoginOrRegister] = useState(true);
  const [isNeedDisabled, setIsNeedDisabled] = useState(false);
  const [customer, setCustomer] = useState({
    email: "",
    password: "",
  });
  const [isOpenPasswordResetNodal, setIsOpenPasswordResetNodal] =
    useState(false);

  const history = useHistory();
  const { params } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const { register, errors, trigger } = useForm();
  const { isAuthenticated, isOpenLoginModal, displayName } = useSelector(
    (state) => state.auth,
  );

  const classes = useStyles();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setIsOpenLoginModal(false));
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    setAuthError("");
    setIsLoginOrRegister(true);
  }, [isOpenLoginModal]);

  const handleClose = () => {
    dispatch(setIsOpenLoginModal(false));
  };

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        logInfo({
          message: `User ${getLocalStore("userId")} logged out success`,
        });

        removeLocalStore("idToken");
        removeLocalStore("username");
        removeLocalStore("userId");
        window.EATPRESTO.postMessage("logout_success");
      })
      .catch((error) => {
        logError({
          message: `Error logging out user ${getLocalStore("userId")}`,
          error,
        });
      });
  };

  const handleChangeLoginStatus = () => {
    setIsLoginOrRegister(!isLoginOrRegister);
  };

  return (
    <Dialog
      classes={{ root: classes.root }}
      open={isOpenLoginModal}
      keepMounted={false}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-labelledby="login-dialog"
      aria-describedby="login-dialog-content"
    >
      <div className={classes.titleSection}>
        {!isAuthenticated && (
          <Typography variant="h5">Come Here Often?</Typography>
        )}
        {!isAuthenticated && (
          <Typography variant="subtitle1">
            Connect with us for fast checkout
          </Typography>
        )}
        {isAuthenticated && (
          <>
            <Typography variant="h5">Hello {displayName}</Typography>
            <Typography variant="subtitle1">
              Want to login as a different user ?
            </Typography>
            <ButtonContained height="40px" onClick={handleLogout}>
              Log me out
            </ButtonContained>
          </>
        )}
      </div>
      <div style={{ padding: 16 }}>
        <div style={{ padding: "4px 0" }}>
          {authError && (
            <MuiAlert severity="error" elevation={6} variant="filled">
              {authError}
            </MuiAlert>
          )}
        </div>

        {isLoginOrRegister && (
          <SignInPage
            setIsOpenPasswordResetNodal={setIsOpenPasswordResetNodal}
            handleChangeLoginStatus={handleChangeLoginStatus}
            authError={authError}
            setAuthError={setAuthError}
          />
        )}

        {!isLoginOrRegister && (
          <RegistrationPage
            setIsOpenPasswordResetNodal={setIsOpenPasswordResetNodal}
            handleChangeLoginStatus={handleChangeLoginStatus}
            authError={authError}
            setAuthError={setAuthError}
          />
        )}

        <span style={{ margin: 8 }} />
      </div>
    </Dialog>
  );
};

LoginDialog.propTypes = {};
LoginDialog.defaultProps = {};

export default LoginDialog;
