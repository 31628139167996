import * as React from "react";

import { isFunction } from "lodash";

import fbIcon from "../../../../../assets/icons/fb.png";
import firebase from "../../../../../utils/firebase";
import SocialSignInButton from "../../../../common/buttons/SocialSignInButton";
import useRouteNavigation from "../../../../../hooks/RouteHooks/useRouteNavigation";
import { logError, logInfo } from "../../../../../services/logging";
import { setLocalStore } from "../../../../../utils/storage/localStorage";

const ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST =
  "An account already exists with the same email address via Google sign-in. Please use Google to sign-in";

const ERROR_MESSAGE_IDB_DATABASE =
  "Oops! Something went wrong while trying to sign you in. Please try again in a few seconds. If the issue persists, you can refresh your browser, use a different sign-in method, or continue as a guest. We apologise for the inconvenience.";

const FacebookSignIn = ({ setAuthError, isNeedDisabled }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { redirectFlowNextRoute } = useRouteNavigation();

  const handleClick = async () => {
    try {
      if (isNeedDisabled) return;
      setAuthError("");
      setIsLoading(true);
      const provider = new firebase.auth.FacebookAuthProvider();
      const result = await firebase.auth().signInWithPopup(provider);

      const token = result.credential.accessToken;
      setLocalStore("idToken", token);
      const { user } = result;
      logInfo({
        message: `GoogleSignIn: User [name: ${user.displayName}, uid: ${user.uid}]  login success`,
      });

      // after authenticate process
      setTimeout(() => {
        if (window.authCallback && isFunction(window.authCallback)) {
          window.authCallback();
          window.authCallback = null;
        }
        redirectFlowNextRoute();
      }, 1000);
    } catch (error) {
      // The email of the user's account used.
      const { email } = error;
      // The firebase.auth.AuthCredential type that was used.
      const { credential } = error;
      const errorCode = error.code;
      const errorMessage = error.message;
      const isIdbError = errorMessage.includes("IDBDatabase");

      if (errorCode === "auth/account-exists-with-different-credential") {
        logError({
          message: ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST,
          error,
          errorCode,
          email,
          credential,
        });
        setIsLoading(false);
        const message = isIdbError
          ? ERROR_MESSAGE_IDB_DATABASE
          : ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST;
        return setAuthError(message);
      }

      const message = isIdbError ? ERROR_MESSAGE_IDB_DATABASE : error.message;
      setAuthError(message);
      setIsLoading(false);
      logError({
        message: "Facebook-Sign-in: Error login user with facebook sign in",
        error,
        credential,
        email,
        errorCode,
      });
    }
  };

  return (
    <SocialSignInButton
      handleClick={handleClick}
      logoSrc={fbIcon}
      labelText="Sign in with Facebook"
      alt="Facebook sign in"
      isLoading={isLoading}
      disabled={isNeedDisabled}
    />
  );
};

export default FacebookSignIn;
