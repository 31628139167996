import * as React from "react";

import { isFunction } from "lodash";

import fbIcon from "../../../../assets/icons/fb.png";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import firebase from "../../../../utils/firebase";
import { setLocalStore } from "../../../../utils/storage/localStorage";
import SocialSignInButton from "../../../common/buttons/SocialSignInButton";
import { customerUpdate, getCustomerValidation } from "../../../../services/customerService";
import { useDispatch } from "react-redux";
import { loginUserSuccess } from "../../../../redux/actions/auth";

const ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST =
  "An account already exists with the same email address via Google sign-in. Please use Google to sign-in";

const ERROR_MESSAGE_IDB_DATABASE =
  "Oops! Something went wrong while trying to sign you in. Please try again in a few seconds. If the issue persists, you can refresh your browser, use a different sign-in method, or continue as a guest. We apologise for the inconvenience.";

const FacebookSignIn = ({ setAuthError, isNeedDisabled,setIsNeedDisabled }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const { params } = useRouteMatch();
  const location = useLocation();

  const handleClick = async () => {
    try {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      setIsLoading(true);
      setIsNeedDisabled(true);
  
      setAuthError("");
      setIsLoading(true);
      const provider = new firebase.auth.FacebookAuthProvider();
      const result = await firebase.auth().signInWithPopup(provider);

      const token = result.credential.accessToken;
      setLocalStore("idToken", token);
      const { user } = result;
  
      if (user) {
        // Check if the email is verified (Google users are usually verified by default)
        if (!user.emailVerified) {
          await user.sendEmailVerification();
          setAuthError(
            "A verification email has been sent to your email address. Please verify your email before logging in."
          );
          setIsLoading(false);
          setIsNeedDisabled(false);
        } else {
          try {
            // Attempt to validate the customer in your database
            console.log("Checking if the user exists in the database...");
            const res = await getCustomerValidation(user.email);
            console.log("Customer validation response:", res.exists, res.data.data);
  
            if (res?.data?.data) {
              // User exists in your database, store token and redirect
              const idToken = await user.getIdToken(true);
              setLocalStore("idToken", idToken);
  
              dispatch(
                loginUserSuccess(
                  user.displayName,
                  user.email
                )
              );
  
              setTimeout(() => {
                if (window.authCallback && isFunction(window.authCallback)) {
                  window.authCallback();
                  window.authCallback = null;
                }
                history.push({
                  pathname: `/location/${params.locationId}/shop`,
                  search: location.search,
                });
                setIsLoading(false);
                setIsNeedDisabled(false);
              }, 1000);
            } else {
              // User does not exist in your database, add them
              console.log("User not found, adding to the database...");
              await customerUpdate(user.email, user.displayName, '');
  
              const idToken = await user.getIdToken(true);
              setLocalStore("idToken", idToken);
  
              dispatch(
                loginUserSuccess(
                  user.displayName,
                  user.email
                )
              );
  
              // Redirect to the shop
              setTimeout(() => {
                history.push({
                  pathname: `/location/${params.locationId}/shop`,
                  search: location.search,
                });
                setIsLoading(false);
                setIsNeedDisabled(false);
              }, 1000);
            }
          } catch (error) {
            console.error("Error during customer validation:", error);
  
            // Handle 404 or other errors
            if (error.response && error.response.status === 404) {
              try {
                console.log("User not found in DB, adding...", user);
                await customerUpdate(user.email, user.displayName, '');
                console.log("User added to the database");
              } catch (updateError) {
                console.error("Error adding user to database:", updateError);
              }
            } else {
              console.error("An unexpected error occurred:", error);
            }
            setIsLoading(false);
            setIsNeedDisabled(false);
          }
        }
      }
    } catch (error) {
      console.error("Google sign-in error:", error);
  
      // Handle Google sign-in errors
      setAuthError(error.message);
      setIsLoading(false);
      setIsNeedDisabled(false);
    }
  };

  return (
    <SocialSignInButton
      handleClick={handleClick}
      logoSrc={fbIcon}
      labelText="Sign in with Facebook"
      alt="Facebook sign in"
      isLoading={isLoading}
      disabled={isNeedDisabled}
    />
  );
};

export default FacebookSignIn;
