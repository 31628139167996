import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { logError, logInfo } from "../../../../../services/logging";
import firebase from "../../../../../utils/firebase";
import {
  getLocalStore,
  removeLocalStore,
  setLocalStore,
} from "../../../../../utils/storage/localStorage";
import { useForm } from "react-hook-form";
import { isFunction } from "lodash";
import GoogleSignIn from "../GoogleSignIn";
import FacebookSignIn from "../FacebookSignIn";
import FirebaseAnonymousSignIn from "../FirebaseAnonymousSignIn";
import ButtonDefault from "../../../../common/buttons/ButtonDefault";
import TextFieldCommon from "../../../../common/textFields/TextFieldCommon";
import {
  loginUserSuccess,
  setIsOpenLoginModal,
} from "../../../../../redux/actions/auth";
import useRouteNavigation from "../../../../../hooks/RouteHooks/useRouteNavigation";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperFullWidth": {
      borderRadius: theme.shape.borderRadius,
    },
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
      },
    },
  },
  form: {
    padding: theme.spacing(1, 1, 0, 1),
    display: "flex",
    flexDirection: "column",
    // color: theme.palette.text.primaryTextColor,
  },
  titleSection: {
    padding: theme.spacing(2),
    textAlign: "center",
    // color: theme.palette.text.primaryTextColor,
  },
  makeMiddle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textStyle: {
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));

const ERROR_MESSAGE_IDB_DATABASE =
  "Oops! Something went wrong while trying to sign you in. Please try again in a few seconds. If the issue persists, you can refresh your browser, use a different sign-in method, or continue as a guest. We apologise for the inconvenience.";

const SignInPage = ({
  setIsOpenPasswordResetNodal,
  handleChangeLoginStatus,
  authError,
  setAuthError,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNeedDisabled, setIsNeedDisabled] = useState(false);
  const [customer, setCustomer] = useState({
    email: "",
    password: "",
  });
  const { redirectFlowNextRoute } = useRouteNavigation();
  const history = useHistory();
  const { params } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const { register, errors, trigger } = useForm();
  const { isAuthenticated, isOpenLoginModal, displayName } = useSelector(
    (state) => state.auth,
  );
  const classes = useStyles();

  useEffect(() => {
    setAuthError("");
  }, [isOpenLoginModal]);

  const handleClose = () => {
    dispatch(setIsOpenLoginModal(false));
  };

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        logInfo({
          message: `User ${getLocalStore("userId")} logged out success`,
        });

        removeLocalStore("idToken");
        removeLocalStore("username");
        removeLocalStore("userId");
        window.EATPRESTO.postMessage("logout_success");
      })
      .catch((error) => {
        logError({
          message: `Error logging out user ${getLocalStore("userId")}`,
          error,
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "marketing") {
      if (value === "true") {
        setCustomer({ ...customer, [name]: false });
      } else {
        setCustomer({ ...customer, [name]: true });
      }
    } else {
      setCustomer({ ...customer, [name]: value });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        setIsLoading(true);
        setIsNeedDisabled(true);
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        firebase
          .auth()
          .signInWithEmailAndPassword(customer.email, customer.password)
          .then(function (result) {
            // result.user.tenantId should be ‘TENANT_PROJECT_ID’.
            firebase.auth().onAuthStateChanged((firebaseUser) => {
              if (firebaseUser) {
                // getCustomerValidation
                firebaseUser.getIdToken(true).then(function (idToken) {
                  const token = idToken;
                  setLocalStore("idToken", token);
                  dispatch(setIsOpenLoginModal(false));
                  dispatch(
                    loginUserSuccess(
                      firebaseUser?.displayName,
                      firebaseUser?.email,
                    ),
                  );

                  setTimeout(() => {
                    if (
                      window.authCallback &&
                      isFunction(window.authCallback)
                    ) {
                      window.authCallback();
                      window.authCallback = null;
                    }
                    redirectFlowNextRoute();
                    setIsNeedDisabled(false);
                    setIsLoading(false);
                  }, 1000);
                });
              }
            });
          })
          .catch(function (error) {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            const isIdbError = errorMessage.includes("IDBDatabase");

            const message = isIdbError
              ? ERROR_MESSAGE_IDB_DATABASE
              : error.message;
            setAuthError(message);
            // The email of the user's account used.
            const { email } = error;
            // The firebase.auth.AuthCredential type that was used.
            const { credential } = error;
            logError({
              message:
                "Firebase AnonymousSignIn: Error login user with AnonymousSignIn",
              error,
              credential,
              email,
              errorCode,
            });
            setIsLoading(false);
            setIsNeedDisabled(false);
          });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        const isIdbError = errorMessage.includes("IDBDatabase");

        const message = isIdbError ? ERROR_MESSAGE_IDB_DATABASE : error.message;
        setAuthError(message);
        // The email of the user's account used.
        const { email } = error;
        // The firebase.auth.AuthCredential type that was used.
        const { credential } = error;
        logError({
          message:
            "Firebase AnonymousSignIn: Error login user with AnonymousSignIn",
          error,
          credential,
          email,
          errorCode,
        });
        setIsLoading(false);
        setIsNeedDisabled(false);
      });
  };

  const trimValues = useCallback(async () => {
    setCustomer({
      email: customer.email.trim(),
      password: customer.password.trim(),
    });
  }, [customer]);

  const handleContinue = async () => {
    setAuthError("");
    // Trim white spaces from the form values.
    trimValues();

    // Trigger form validation using the useForm trigger function.
    // This will validate all the registered fields and return a boolean indicating if the form is valid.
    const isValid = await trigger();

    // If the form is not valid, return early and do not proceed.
    if (!isValid) return;

    handleLogin();
  };

  const handleOpenPasswordResetModal = () => {
    setIsOpenPasswordResetNodal(true);
  };

  const theme = useTheme();
  return (
    <Grid
      container
      spacing={2}
      className={classes.makeMiddle}
      style={{ marginTop: 8 }}
    >
      <Grid item xs={12}>
        <TextFieldCommon
          id="email"
          name="email"
          label="Email address"
          value={customer.email}
          variant="outlined"
          onChange={handleChange}
          fullWidth
          style={{ color: "white" }}
          inputRef={register({
            required: true,
            minLength: 2,
            maxLength: 80,
          })}
          InputLabelProps={{
            style: { color: "white" },
          }}
          InputProps={{
            style: { color: "white" },
          }}
          helperText={errors.email ? "Please enter email address" : ""}
          error={errors.email?.type}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldCommon
          id="password"
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          value={customer.password}
          variant="outlined"
          onChange={handleChange}
          fullWidth
          style={{ color: "white" }}
          inputRef={register({
            required: true,
            minLength: 2,
            maxLength: 80,
          })}
          InputLabelProps={{
            style: { color: "white" },
          }}
          InputProps={{
            style: { color: "white" },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityIcon style={{ color: "white" }} />
                  ) : (
                    <VisibilityOffIcon style={{ color: "white" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={errors.password ? "Please enter password" : ""}
          error={errors.password?.type}
        />
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "start" }}>
        <Button disabled={isAuthenticated}>
          <Typography
            variant="body2"
            gutterBottom
            align="center"
            style={{
              fontWeight: "bold",
              color: "white",
              textTransform: "none",
            }}
            onClick={handleOpenPasswordResetModal}
          >
            Forgot Password?
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <ButtonDefault
          bgColor={"black"}
          textColor={"white"}
          onClick={handleContinue}
          fullWidth
          disabled={isAuthenticated}
        >
          <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
            {isLoading ? <CircularProgress size={20} /> : "LOGIN"}
          </Typography>
        </ButtonDefault>
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{ display: "flex", alignItems: "center" }}>
          <Grid item xs={3} sm={4}>
            <Divider
              style={{
                margin: "4px 0",
                backgroundColor: theme.palette.primary.contrastText,
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography className={classes.textStyle}>
              or LogIn in with
            </Typography>
          </Grid>
          <Grid item xs={3} sm={4}>
            <Divider
              style={{
                margin: "4px 0",
                backgroundColor: theme.palette.primary.contrastText,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
        <GoogleSignIn
          setAuthError={setAuthError}
          isAuthenticated={isAuthenticated}
        />
      </Grid>
      <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
        <FacebookSignIn
          setAuthError={setAuthError}
          isAuthenticated={isAuthenticated}
        />
      </Grid>
      <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
        <FirebaseAnonymousSignIn
          setAuthError={setAuthError}
          isAuthenticated={isAuthenticated}
        />
      </Grid>

      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
            }}
          >
            Don't have an account?
          </Typography>
          <Button
            onClick={handleChangeLoginStatus}
            style={{ textTransform: "none" }}
            disabled={isAuthenticated}
          >
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                paddingLeft: "8px",
                color: "white",
              }}
            >
              Register
            </Typography>
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

SignInPage.propTypes = {};
SignInPage.defaultProps = {};

export default SignInPage;
