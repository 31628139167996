import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { logError, logInfo } from "../../../../../services/logging";
import firebase from "../../../../../utils/firebase";
import {
  getLocalStore,
  removeLocalStore,
  setLocalStore,
} from "../../../../../utils/storage/localStorage";
import { useForm } from "react-hook-form";
import { isFunction } from "lodash";
import GoogleSignIn from "../GoogleSignIn";
import FacebookSignIn from "../FacebookSignIn";
import FirebaseAnonymousSignIn from "../FirebaseAnonymousSignIn";
import ButtonDefault from "../../../../common/buttons/ButtonDefault";
import TextFieldCommon from "../../../../common/textFields/TextFieldCommon";
import {
  loginUserSuccess,
  setIsOpenLoginModal,
} from "../../../../../redux/actions/auth";
import useRouteNavigation from "../../../../../hooks/RouteHooks/useRouteNavigation";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperFullWidth": {
      borderRadius: theme.shape.borderRadius,
    },
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
      },
    },
  },
  form: {
    padding: theme.spacing(1, 1, 0, 1),
    display: "flex",
    flexDirection: "column",
    // color: theme.palette.text.primaryTextColor,
  },
  titleSection: {
    padding: theme.spacing(2),
    textAlign: "center",
    // color: theme.palette.text.primaryTextColor,
  },
  makeMiddle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textStyle: {
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));

const ERROR_MESSAGE_IDB_DATABASE =
  "Oops! Something went wrong while trying to sign you in. Please try again in a few seconds. If the issue persists, you can refresh your browser, use a different sign-in method, or continue as a guest. We apologise for the inconvenience.";

const RegistrationPage = ({
  setIsOpenPasswordResetNodal,
  handleChangeLoginStatus,
  authError,
  setAuthError,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNeedDisabled, setIsNeedDisabled] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [customer, setCustomer] = useState({
    email: "",
    password: "",
  });
  const { redirectFlowNextRoute } = useRouteNavigation();
  const { params } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();
  const { register, errors, trigger, watch } = useForm();
  const { isAuthenticated, isOpenLoginModal } = useSelector(
    (state) => state.auth,
  );
  const classes = useStyles();

  useEffect(() => {
    setAuthError("");
  }, [isOpenLoginModal]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "marketing") {
      if (value === "true") {
        setCustomer({ ...customer, [name]: false });
      } else {
        setCustomer({ ...customer, [name]: true });
      }
    } else {
      setCustomer({ ...customer, [name]: value });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = async () => {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        setIsLoading(true);
        setIsNeedDisabled(true);
        // Create new user with email and password
        firebase
          .auth()
          .createUserWithEmailAndPassword(customer.email, customer.password)
          .then(function (result) {
            // Set first and last name to user profile
            const { firstName, lastName } = customer;
            result.user
              .updateProfile({
                displayName: `${firstName} ${lastName}`,
              })
              .then(() => {
                firebase.auth().onAuthStateChanged((firebaseUser) => {
                  if (firebaseUser) {
                    // getCustomerValidation
                    firebaseUser.getIdToken(true).then(function (idToken) {
                      const token = idToken;
                      setLocalStore("idToken", token);
                      dispatch(
                        loginUserSuccess(
                          firebaseUser?.displayName,
                          firebaseUser?.email,
                        ),
                      );

                      setTimeout(() => {
                        if (
                          window.authCallback &&
                          isFunction(window.authCallback)
                        ) {
                          window.authCallback();
                          window.authCallback = null;
                        }
                        redirectFlowNextRoute();
                        setIsNeedDisabled(false);
                        setIsLoading(false);
                      }, 1000);
                    });
                  }
                });
              });
          })
          .catch(function (error) {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            const isIdbError = errorMessage.includes("IDBDatabase");

            const message = isIdbError
              ? ERROR_MESSAGE_IDB_DATABASE
              : error.message;
            setAuthError(message);
            const { email } = error;
            const { credential } = error;
            logError({
              message:
                "Firebase Register: Error creating new user with email and password",
              error,
              credential,
              email,
              errorCode,
            });
            setIsLoading(false);
            setIsNeedDisabled(false);
          });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        const isIdbError = errorMessage.includes("IDBDatabase");

        const message = isIdbError ? ERROR_MESSAGE_IDB_DATABASE : error.message;
        setAuthError(message);
        const { email } = error;
        const { credential } = error;
        logError({
          message: "Firebase Register: Error setting persistence for new user",
          error,
          credential,
          email,
          errorCode,
        });
        setIsLoading(false);
        setIsNeedDisabled(false);
      });
  };

  const trimValues = useCallback(async () => {
    setCustomer({
      firstName: customer?.firstName?.trim(),
      lastName: customer?.lastName?.trim(),
      confirmPassword: customer.confirmPassword?.trim(),
      email: customer?.email?.trim(),
      password: customer?.password?.trim(),
    });
  }, [customer]);

  const handleContinue = async () => {
    setAuthError("");
    // Trim white spaces from the form values.
    trimValues();

    // Trigger form validation using the useForm trigger function.
    // This will validate all the registered fields and return a boolean indicating if the form is valid.
    const isValid = await trigger();

    // If the form is not valid, return early and do not proceed.
    if (!isValid) return;

    handleRegister();
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={2}
      className={classes.makeMiddle}
      style={{ marginTop: 8 }}
    >
      <Grid item xs={12}>
        <TextFieldCommon
          id="firstName"
          name="firstName"
          label="First Name"
          value={customer.firstName}
          variant="outlined"
          onChange={handleChange}
          fullWidth
          inputRef={register({
            required: true,
            minLength: 2,
            maxLength: 80,
          })}
          InputLabelProps={{
            style: { color: "white" },
          }}
          InputProps={{
            style: { color: "white" },
          }}
          helperText={errors.firstName ? "Please enter First Name" : ""}
          error={errors.firstName?.type}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldCommon
          id="lastName"
          name="lastName"
          label="Last Name"
          value={customer.lastName}
          variant="outlined"
          onChange={handleChange}
          fullWidth
          inputRef={register({
            required: true,
            minLength: 2,
            maxLength: 80,
          })}
          InputLabelProps={{
            style: { color: "white" },
          }}
          InputProps={{
            style: { color: "white" },
          }}
          helperText={errors.lastName ? "Please enter Last Name" : ""}
          error={errors.lastName?.type}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldCommon
          id="email"
          name="email"
          label="Email Address"
          value={customer.email}
          variant="outlined"
          type="email"
          onChange={handleChange}
          fullWidth
          InputLabelProps={{
            style: { color: "white" },
          }}
          InputProps={{
            style: { color: "white" },
          }}
          inputRef={register({
            required: true,
            minLength: 2,
            maxLength: 80,
          })}
          helperText={errors.email ? "Please enter Email Address" : ""}
          error={errors.email?.type}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldCommon
          id="password"
          name="password"
          label="Password"
          value={customer.password}
          variant="outlined"
          type={showPassword ? "text" : "password"}
          onChange={handleChange}
          fullWidth
          InputLabelProps={{
            style: { color: "white" },
          }}
          inputRef={register({
            required: true,
            minLength: 2,
            maxLength: 80,
          })}
          helperText={errors.password ? "Please enter Password" : ""}
          error={errors.password?.type}
          InputProps={{
            style: { color: "white" },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityIcon style={{ color: "white" }} />
                  ) : (
                    <VisibilityOffIcon style={{ color: "white" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldCommon
          id="confirmPassword"
          name="confirmPassword"
          label="Confirm Password"
          value={customer.confirmPassword}
          variant="outlined"
          onChange={handleChange}
          fullWidth
          type={showConfirmPassword ? "text" : "password"}
          InputLabelProps={{
            style: { color: "white" },
          }}
          inputRef={register({
            required: true,
            minLength: 2,
            maxLength: 80,
            validate: (value) =>
              value === customer.password || "Passwords do not match",
          })}
          helperText={errors.confirmPassword ? "Password mismatch" : ""}
          error={errors.confirmPassword?.type}
          InputProps={{
            style: { color: "white" },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleConfirmPasswordVisibility}
                  edge="end"
                >
                  {showConfirmPassword ? (
                    <VisibilityIcon style={{ color: "white" }} />
                  ) : (
                    <VisibilityOffIcon style={{ color: "white" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <ButtonDefault
          bgColor={"black"}
          textColor={"white"}
          onClick={handleContinue}
          fullWidth
        >
            <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
              {isLoading ? <CircularProgress size={20} /> : "REGISTER"}
            </Typography>
        </ButtonDefault>
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{ display: "flex", alignItems: "center" }}>
          <Grid item xs={3} sm={4}>
            <Divider
              style={{
                margin: "4px 0",
                backgroundColor: theme.palette.primary.contrastText,
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography className={classes.textStyle}>
              or Register with
            </Typography>
          </Grid>
          <Grid item xs={3} sm={4}>
            <Divider
              style={{
                margin: "4px 0",
                backgroundColor: theme.palette.primary.contrastText,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
        <GoogleSignIn
          setAuthError={setAuthError}
          isAuthenticated={isAuthenticated}
        />
      </Grid>
      <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
        <FacebookSignIn
          setAuthError={setAuthError}
          isAuthenticated={isAuthenticated}
        />
      </Grid>

      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
            }}
          >
            Don't have an account?
          </Typography>
          <Button
            onClick={handleChangeLoginStatus}
            style={{ textTransform: "none" }}
            disabled={isAuthenticated}
          >
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                paddingLeft: "8px",
                color: "white",
              }}
            >
              Login
            </Typography>
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

RegistrationPage.propTypes = {};
RegistrationPage.defaultProps = {};

export default RegistrationPage;
