import React, { useEffect } from "react";

import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useCallback } from "react";
import ButtonDefault from "../../common/buttons/ButtonDefault";
import firebase from "firebase";
import { logError } from "../../../services/logging";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import FacebookSignIn from "./FacebookSignIn";
import TextFieldCommon from "../../common/textFields/TextFieldCommon";
import { isFunction } from "lodash";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  loginUserSuccess,
  setIsOpenLoginModal,
} from "../../../redux/actions/auth";
import { setLocalStore } from "../../../utils/utils";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import AlertMessage from "../../common/AlertMessage";
import {
  customerUpdate,
  getCustomerValidation,
} from "../../../services/customerService";
import GoogleSignUp from "./GoogleSignIn";

const ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST =
  "An account already exists with the same email address via Google sign-in. Please use Google to sign-in";

const ERROR_MESSAGE_IDB_DATABASE =
  "Oops! Something went wrong while trying to sign you in. Please try again in a few seconds. If the issue persists, you can refresh your browser, use a different sign-in method, or continue as a guest. We apologise for the inconvenience.";

const useStyles = makeStyles((theme) => ({
  makeMiddle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    backgroundColor: "white",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    },
  },
  textStyle: {},
}));

const RegisterPage = () => {
  const [customer, setCustomer] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isNeedDisabled, setIsNeedDisabled] = useState(false);
  const [authError, setAuthError] = useState("");
  const [isOpenPasswordResetNodal, setIsOpenPasswordResetNodal] =
    useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const theme = useTheme();
  const { register, errors, trigger } = useForm();
  const history = useHistory();
  const { params } = useRouteMatch();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isAuthenticated) {
      if (window.authCallback && isFunction(window.authCallback)) {
        window.authCallback();
        window.authCallback = null;
      }
      dispatch(setIsOpenLoginModal(false));
      history.push({
        pathname: `/location/${params.locationId}/shop`,
        search: location.search,
      });
    }
  }, [isAuthenticated]);

  const trimValues = useCallback(async () => {
    setCustomer({
      firstName: customer.firstName.trim(),
      lastName: customer.lastName.trim(),
      email: customer.email.trim(),
      password: customer.password.trim(),
      confirmPassword: customer.confirmPassword.trim(),
    });
  }, [customer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "marketing") {
      if (value === "true") {
        setCustomer({ ...customer, [name]: false });
      } else {
        setCustomer({ ...customer, [name]: true });
      }
    } else {
      setCustomer({ ...customer, [name]: value });
    }
  };

  const handleRegister = async () => {
    try {
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      setIsLoading(true);
      setIsNeedDisabled(true);

      // Register the user with email and password in Firebase
      const result = await firebase
        .auth()
        .createUserWithEmailAndPassword(customer.email, customer.password);
      firebase.auth().onAuthStateChanged(async (firebaseUser) => {
        if (firebaseUser) {
          // Check if the email is verified
          if (!firebaseUser.emailVerified) {
            await firebaseUser.sendEmailVerification();
            setAuthError(
              "A verification email has been sent to your email address. Please verify your email before logging in.",
            );
            setIsLoading(false);
            setIsNeedDisabled(false);
          } else {
            try {
              // Attempt to validate the customer in your database
              console.log("Checking if the user exists in the database...");
              const res = await getCustomerValidation(firebaseUser.email);
              console.log("Customer validation response:", res);

              if (res.data.data) {
                // User exists in your database, store token and redirect
                const idToken = await firebaseUser.getIdToken(true);
                setLocalStore("idToken", idToken);

                dispatch(
                  loginUserSuccess(
                    firebaseUser.displayName,
                    firebaseUser.email,
                  ),
                );

                setTimeout(() => {
                  if (window.authCallback && isFunction(window.authCallback)) {
                    window.authCallback();
                    window.authCallback = null;
                  }
                  history.push({
                    pathname: `/location/${params.locationId}/shop`,
                    search: location.search,
                  });
                  setIsLoading(false);
                  setIsNeedDisabled(false);
                }, 1000);
              } else {
                // User does not exist in your database, add them
                console.log("User not found, adding to the database...");
                await customerUpdate(
                  customer.email,
                  customer.firstName,
                  customer.lastName,
                );

                const idToken = await firebaseUser.getIdToken(true);
                setLocalStore("idToken", idToken);

                dispatch(
                  loginUserSuccess(
                    firebaseUser.displayName,
                    firebaseUser.email,
                  ),
                );

                // Redirect to the shop
                setTimeout(() => {
                  history.push({
                    pathname: `/location/${params.locationId}/shop`,
                    search: location.search,
                  });
                  setIsLoading(false);
                  setIsNeedDisabled(false);
                }, 1000);
              }
            } catch (error) {
              console.error("Error during customer validation:", error);

              // Check if it's a 404 error
              if (error.response && error.response.status === 404) {
                // User is not in your database, add them
                try {
                  console.log("User not found in DB, adding...");
                  await customerUpdate(
                    customer.email,
                    customer.firstName,
                    customer.lastName,
                  );
                  console.log("User added to the database");
                } catch (updateError) {
                  console.error("Error adding user to database:", updateError);
                }
              } else {
                console.error("An unexpected error occurred:", error);
              }
              setIsLoading(false);
              setIsNeedDisabled(false);
            }
          }
        }
      });
    } catch (error) {
      // Handle registration or persistence errors
      firebase
        .auth()
        .signInWithEmailAndPassword(customer.email, customer.password)
        .then(function (result) {
          // result.user.tenantId should be ‘TENANT_PROJECT_ID’.
          firebase.auth().onAuthStateChanged((firebaseUser) => {
            if (firebaseUser) {
              // getCustomerValidation
              firebaseUser.getIdToken(true).then(function (idToken) {
                const token = idToken;
                setLocalStore("idToken", token);
                dispatch(setIsOpenLoginModal(false));
                dispatch(
                  loginUserSuccess(
                    firebaseUser?.displayName,
                    firebaseUser?.email,
                  ),
                );

                setTimeout(() => {
                  if (window.authCallback && isFunction(window.authCallback)) {
                    window.authCallback();
                    window.authCallback = null;
                  }
                  history.push({
                    pathname: `/location/${params.locationId}/shop`,
                    search: location.search,
                  });
                  setIsNeedDisabled(false);
                  setIsLoading(false);
                }, 1000);
              });
            }
          });
        })
        .catch(function (error) {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          const isIdbError = errorMessage.includes("IDBDatabase");

          const message = isIdbError
            ? ERROR_MESSAGE_IDB_DATABASE
            : error.message;
          setAuthError(message);
          // The email of the user's account used.
          const { email } = error;
          // The firebase.auth.AuthCredential type that was used.
          const { credential } = error;
          logError({
            message:
              "Firebase AnonymousSignIn: Error login user with AnonymousSignIn",
            error,
            credential,
            email,
            errorCode,
          });
          setIsLoading(false);
          setIsNeedDisabled(false);
        });
      setAuthError(error.message);
      setIsLoading(false);
      setIsNeedDisabled(false);
    }
  };

  const handleContinue = async () => {
    setAuthError("");
    // Trim white spaces from the form values.
    trimValues();

    // Trigger form validation using the useForm trigger function.
    // This will validate all the registered fields and return a boolean indicating if the form is valid.
    const isValid = await trigger();

    // If the form is not valid, return early and do not proceed.
    if (!isValid) return;

    handleRegister();
  };

  const handleOpenPasswordResetModal = () => {
    setIsOpenPasswordResetNodal(true);
  };
  /**
   * The handleContinue function is responsible for calling the handleLogin function
   * and logging in the user.
   */

  const handleRedirectSignInPage = () => {
    history.push({
      pathname: `/location/${params.locationId}/signIn`,
      search: location.search,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} className={classes.textStyle}>
            <Typography
              variant="h6"
              gutterBottom
              align="center"
              style={{ fontWeight: "bold" }}
              className={classes.textStyle}
            >
              Register
            </Typography>
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={12} sm={8} md={6}>
            <TextFieldCommon
              id="firstName"
              name="firstName"
              label="First Name"
              value={customer.firstName}
              variant="outlined"
              onChange={handleChange}
              fullWidth
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                style: { color: "black" },
              }}
              helperText={errors.firstName ? "Please enter First Name" : ""}
              error={errors.firstName?.type}
            />
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>{" "}
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={12} sm={8} md={6}>
            <TextFieldCommon
              id="lastName"
              name="lastName"
              label="Last Name"
              value={customer.lastName}
              variant="outlined"
              onChange={handleChange}
              fullWidth
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                style: { color: "black" },
              }}
              helperText={errors.lastName ? "Please enter Last Name" : ""}
              error={errors.lastName?.type}
            />
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={12} sm={8} md={6}>
            <TextFieldCommon
              id="email"
              name="email"
              label="Email Address"
              value={customer.email}
              variant="outlined"
              type="email"
              onChange={handleChange}
              fullWidth
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                style: { color: "black" },
              }}
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
              helperText={errors.email ? "Please enter Email Address" : ""}
              error={errors.email?.type}
            />
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={12} sm={8} md={6}>
            <TextFieldCommon
              id="password"
              name="password"
              label="Password"
              value={customer.password}
              variant="outlined"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              fullWidth
               InputLabelProps={{
                style: { color: "black" },
              }}
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
              helperText={errors.password ? "Please enter Password" : ""}
              error={errors.password?.type}
              InputProps={{
                style: { color: "black" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon style={{ color: "black" }} />
                      ) : (
                        <VisibilityOffIcon style={{ color: "black" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={12} sm={8} md={6}>
            <TextFieldCommon
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              value={customer.confirmPassword}
              variant="outlined"
              onChange={handleChange}
              fullWidth
              type={showConfirmPassword ? "text" : "password"}
              InputLabelProps={{
                style: { color: "black" },
              }}
              inputRef={register({
                required: true,
                minLength: 2,
                maxLength: 30,
              })}
              helperText={
                errors.confirmPassword ? "Please enter Confirm Password" : ""
              }
              error={errors.confirmPassword?.type}
              InputProps={{
                style: { color: "black" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleConfirmPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityIcon style={{ color: "black" }} />
                      ) : (
                        <VisibilityOffIcon style={{ color: "black" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={12} sm={8} md={6} style={{ marginTop: "24px" }}>
            <ButtonDefault
              bgColor={"black"}
              textColor={"white"}
              onClick={handleContinue}
              fullWidth
            >
              <Button
                style={{
                  marginLeft: "8px",
                  padding: "4px",
                  minWidth: "40px",
                }}
                onClick={handleContinue}
              >
                <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {isLoading ? <CircularProgress size={20} /> : "REGISTER"}
                </Typography>
              </Button>
            </ButtonDefault>
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            style={{ marginTop: "8px", alignItems: "center" }}
          >
            <Grid container style={{ display: "flex", alignItems: "center" }}>
              <Grid item xs={3} sm={4}>
                <Divider
                  style={{
                    margin: "4px 0",
                    backgroundColor: theme.palette.primary.contrastText,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  style={{
                    fontSize: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  or Register with
                </Typography>
              </Grid>
              <Grid item xs={3} sm={4}>
                <Divider
                  style={{
                    margin: "4px 0",
                    backgroundColor: theme.palette.primary.contrastText,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={8} md={6} style={{ marginTop: "24px" }}>
            <div>
              <div style={{ padding: "4px 0" }}>
                {authError && (
                  <MuiAlert severity="error" elevation={6} variant="outlined">
                    {authError}
                  </MuiAlert>
                )}
              </div>

              <Grid
                container
                spacing={2}
                className={classes.makeMiddle}
                style={{ marginTop: 8 }}
              >
                <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
                  <GoogleSignUp
                    setAuthError={setAuthError}
                    isNeedDisabled={isNeedDisabled}
                    setIsNeedDisabled={setIsNeedDisabled}
                  />
                </Grid>
                <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
                  <FacebookSignIn
                    setAuthError={setAuthError}
                    isNeedDisabled={isNeedDisabled}
                    setIsNeedDisabled={setIsNeedDisabled}
                  />
                </Grid>
                {/* <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
            <FirebaseAnonymousSignIn
              setAuthError={setAuthError}
              isLoading={isLoading}
            />
          </Grid> */}
              </Grid>

              <span style={{ margin: 8 }} />

              {/* <MicrosoftSignIn />
        <span style={{ margin: 8 }} />
        <FacebookSignIn /> */}
            </div>
          </Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={0} sm={2} md={3}></Grid>
          <Grid item xs={12} sm={8} md={6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Typography
                style={{
                  fontSize: "18px",
                }}
              >
                Already have an account?
              </Typography>
              <Button onClick={handleRedirectSignInPage}>
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    paddingLeft: "8px",
                    color: "black",
                    textTransform: "none",
                  }}
                >
                  Sign In
                </Typography>
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>

      <AlertMessage
        message={alertMessage}
        setMessage={setAlertMessage}
        severity="error"
        anchorOrigin="bottom"
      />
    </>
  );
};

export default RegisterPage;
