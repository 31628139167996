import axiosClient from "./client";

export function getAllLocationInfo(locationId, size, currentPage) {
  return axiosClient({
    method: "GET",
    url: `${process.env.REACT_APP_API_ENDPOINT_CUSTOMER}/public-auth/customer`,
  });
}

export function getCustomerValidation(email) {
  return axiosClient({
    method: "GET",
    url: `${process.env.REACT_APP_API_ENDPOINT_CUSTOMER}/internal/customer/search/${email}`,
  });
}


export function customerUpdate(email,firstName, lastName) {
  return axiosClient({
    method: "GET",
    url: `${process.env.REACT_APP_API_ENDPOINT_CUSTOMER}/internal/customer/search/${email}/${firstName}/${lastName}`,
  });
}
