import React from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import firebase from "firebase";
import TextFieldCommon from "../../../../common/textFields/TextFieldCommon";
import { useForm } from "react-hook-form";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({}));

const PasswordResetModal = ({
  setOpenDialog,
  openDialog,
  setIsLoading,
  setError,
  isLoading,
  setAlertMessage,
}) => {
  const [email, setEmail] = useState("");
  const { register, errors, trigger } = useForm();

  const handleCancel = () => {
    setOpenDialog(false);
  };

  const handleResetPassword = async () => {
    // Set 'isLoadingPasswordReset' state to true, indicating the start of the process
    setIsLoading(true);
    try {
      // Attempt to send a password reset email using Firebase authentication
      await firebase.auth().sendPasswordResetEmail(email);
      setIsLoading(false);
      setOpenDialog(false);
      setAlertMessage("Please check your email");
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail(value);
  };

  const classes = useStyles();

  return (
    <Dialog
      classes={{ root: classes.modal }}
      open={openDialog}
      onClose={(e, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        setOpenDialog(false);
      }}
      keepMounted
      minWifth="md"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <TextFieldCommon
          id="email"
          name="email"
          label="Email"
          type="email"
          value={email}
          variant="outlined"
          onChange={handleChange}
          fullWidth
          inputRef={register({
            required: true,
            minLength: 2,
            maxLength: 30,
          })}
          helperText={errors.password ? "Please enter password" : ""}
          error={errors.password?.type}
        />
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button onClick={handleCancel} color="inherit">
          Cancel
        </Button>
        <Button onClick={handleResetPassword} color="inherit" autoFocus>
          {isLoading ? <CircularProgress size={20} /> : "Send Reset Link"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordResetModal;
